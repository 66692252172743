<template>
  <div
    class="count-info flex rounded-2.25 bg-#eef7e7 justify-between items-center gap-1 lg:gap-3"
  >
    <button
      class="btn-change size-7 flex items-center justify-center bg-firmennyy/20 disabled:bg-transparent leading-0.8 rounded-2.25 text-firmennyy text-7"
      :disabled="isLoading"
      @click="changeProductCount(product.count - 1)"
    >
      -
    </button>

    <div class="counter w-6 lg:w-8 text-4.5 font-500 text-center text-tekstovyy">{{ product.count }}</div>
    <button
      class="btn-change size-7 flex items-center justify-center bg-firmennyy/20 disabled:bg-transparent leading-1 rounded-2.25 text-firmennyy text-7"
      :disabled="isLoading"
      @click="changeProductCount(product.count + 1)"
    >
      +
    </button>
  </div>
</template>

<script setup lang="ts">
import { useCart } from '~/composables/cart/useCart';
import type { CartProductItemType } from '~/composables/cart/types/CartType';
const { changeCart } = useCart();

const props = defineProps<{
	product: CartProductItemType;
}>();

const isLoading = ref<boolean>(false);

async function changeProductCount (newCount : number) {
  const formData = new FormData();

  formData.append('product_id', String(props.product.product_id));
  formData.append('count', String(newCount));

  let idForm = 0;
  isLoading.value = true;

  props.product.additional_ingredients.forEach((optionItem) => {
    formData.append(`additional_ingredient_ids[${idForm}]`, String(optionItem.product_additional_ingredient.id));
    idForm++;
  });

  await changeCart(formData as any).catch((err) => {
    console.log(err.data.message);
	  isLoading.value = false;
    return null;
  });

  isLoading.value = false;
}
</script>

<style scoped lang="scss">
.template-cart-btn {
	& > .count-info{
		display: flex;
		gap: 12px;
	}
}

</style>
